import { useState } from 'react'
import Icon from 'react-native-vector-icons/FontAwesome'
import { View, TextInput, Dimensions } from 'react-native'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useArticles } from '../../context/ArticleContext'
import Btn from '../Btn'

export default function DesktopSearchBar() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()
  const { SearchState } = useArticles()
  const [searchInput, setSearchInput] = useState<string>(SearchState.search)

  const submitSearch = () => {
    SearchState.setSearch(searchInput.toLocaleLowerCase())
    navigation.navigate('Search')
  }

  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <TextInput
          onChangeText={(text) => setSearchInput(text)}
          value={searchInput}
          onEndEditing={submitSearch}
          style={{
            paddingVertical: 8,
            paddingHorizontal: 40,
            borderRadius: 40,
            width: Dimensions.get('window').width / 7,
            backgroundColor: '#f2f2f2',
            fontSize: 14,
          }}
          placeholder="Search here..."
          placeholderTextColor="gray"
        />

        <View style={{ position: 'absolute', top: 9, left: 15 }}>
          <Icon name="search" size={15} color="gray" />
        </View>
      </View>

      <Btn
        onPress={submitSearch}
        style={{
          borderRadius: 40,
          margin: 5,
          paddingTop: 7,
          paddingBottom: 7,
          paddingLeft: 15,
          paddingRight: 15,
          position: 'absolute',
          right: -5,
          display: 'flex',
          alignItems: 'flex-center',
          justifyContent: 'flex-center',
        }}
        textStyle={{
          fontSize: 12,
          fontWeight: 'bold',
        }}
        text="Search"
      />
    </View>
  )
}
