import { useState } from 'react'
import { Modal, View, Text, StyleSheet, Pressable } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import tw from 'twrnc'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { IS_ON_DESKTOP } from '../../utils/settings'
import { useUser } from '../../context/UserContext'
import { showMessage } from 'react-native-flash-message'
import { type Article } from '../../types/articles'

import Close from 'react-native-vector-icons/Fontisto'
import UniversalModal from '../Modals/UniversalModal'

type LikeButtonProps = {
  article: Article
}

export default function LikeButton({ article }: LikeButtonProps) {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()
  const { UserState, addOrRemoveLikedArticle } = useUser()
  const [modalVisible, setModalVisible] = useState(false)

  let isArticleAlreadyLiked = false
  if (UserState.user !== null) {
    const likedArticlesIds = UserState.user.likedArticles.map(
      (likedArticle) => likedArticle.articleId
    )

    if (likedArticlesIds.includes(article._id)) {
      isArticleAlreadyLiked = true
    }
  }

  const [isLiked, setIsLiked] = useState(isArticleAlreadyLiked)

  const handleLikePress = () => {
    if (UserState.user) {
      addOrRemoveLikedArticle(
        {
          likedArticle: !isLiked,
          articleId: article._id,
        },
        (error) => {
          showMessage({
            message: error,
            type: 'danger',
            hideOnPress: true,
          })
        },
        () => {
          setIsLiked((prevLiked) => !prevLiked)
        }
      )
    } else {
      setIsLiked(false)
      setModalVisible(true)
    }
  }

  const closeModal = () => {
    if (modalVisible) {
      setModalVisible(false)
    }
  }

  const handleLoginLinkPress = () => {
    navigation.navigate('Log In') // Navigate to the 'Log In' screen
  }
  const handleSignUpLinkPress = () => {
    navigation.navigate('Sign Up') // Navigate to the 'Sign Up' screen
  }

  return (
    <View>
      <UniversalModal
        close={closeModal}
        visible={modalVisible}
        content={
          <Text style={styles.modalText}>
            You have to&nbsp;
            <Pressable onPress={handleLoginLinkPress}>
              <Text style={styles.loginLinkText}>Log In</Text>
            </Pressable>
            &nbsp;or&nbsp;
            <Pressable onPress={handleSignUpLinkPress}>
              <Text style={styles.loginLinkText}>Sign Up</Text>
            </Pressable>
            &nbsp;so we can track and save your favorite articles!
          </Text>
        }
      />

      <Pressable
        style={tw`px-2 py-2 mx-2 bg-transparent`}
        onPress={handleLikePress}
      >
        <Icon
          name={isLiked ? 'bookmark' : 'bookmark-o'}
          size={30}
          color={isLiked ? '#E01E26' : 'red'}
          style={tw`mr-2`}
        />
      </Pressable>
    </View>
  )
}

const styles = StyleSheet.create({
  modalText: {
    lineHeight: 30,
    flex: 1,
    justifyContent: 'flex-start',
    fontSize: 18,
    marginBottom: 20,
    textAlign: 'center',
  },
  loginLinkText: {
    textDecorationLine: 'underline',
    fontSize: 17,
    lineHeight: 25,
    fontWeight: '500',
    color: '#144270',
  },
})
