import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ArticleContextData } from '../types/articles'
import {
  extractValuesFromDescription,
  extractValuesFromDescriptionCustomArticles,
} from '../utils/article'
import { API_URL, ARTICLES_PER_PAGE_LIMIT } from '../utils/settings'

const ArticleContext = createContext<ArticleContextData>(undefined)

export function useArticles() {
  return useContext(ArticleContext)
}

export default function ArticleProvider({ children }: { children: ReactNode }) {
  const [articles, setArticles] = useState<any[]>([])
  const [searchingArticles, setSearchingArticles] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [page, setPage] = useState<number>(0)
  const [searchingArticlesPage, setSearchingArticlesPage] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [category, setCategory] = useState<string>('')

  useEffect(() => {
    if (search === '') {
      setSearchingArticles([])
      setSearchingArticlesPage(0)
      return
    }

    refreshSearchingArticles()
  }, [search])

  /** Fetch Initial Articles */
  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      await refreshArticles()
      setIsLoading(false)
    }

    fetchData()
  }, [category])

  const findArticle = async (articleId: string) => {
    try {
      const url = `${API_URL}/custom-news/${articleId}`
      const res = await fetch(url)
      const data = await res.json()

      const articleData = data.data

      return articleData
    } catch (error) {
      console.error(error)
    }
  }

  const fetchArticles = async () => {
    try {
      const url = `${API_URL}/news?limit=${ARTICLES_PER_PAGE_LIMIT}&page=${
        page + 1
      }&category=${encodeURIComponent(category)}`
      const res = await fetch(url)
      const data = await res.json()

      const articleData = [...data.data]

      for (let i = 0; i < articleData.length; i++) {
        const { imgSrc, h1Content, anchorHref } =
          articleData[i].rssId === 'custom-article'
            ? extractValuesFromDescriptionCustomArticles(articleData[i])
            : extractValuesFromDescription(articleData[i].description)

        articleData[i] = { ...articleData[i], imgSrc, h1Content, anchorHref }
      }

      setArticles((prevArticles) => {
        return [...prevArticles, ...articleData]
      })

      setPage((prevPage) => prevPage + 1)
    } catch (error) {
      console.error(error)
    }
  }

  const refreshArticles = async () => {
    try {
      const url = `${API_URL}/news?limit=${ARTICLES_PER_PAGE_LIMIT}&page=${0}&category=${encodeURIComponent(
        category
      )}`
      const res = await fetch(url)
      const data = await res.json()

      const articleData = [...data.data]

      for (let i = 0; i < articleData.length; i++) {
        const { imgSrc, h1Content, anchorHref } =
          articleData[i].rssId === 'custom-article'
            ? extractValuesFromDescriptionCustomArticles(articleData[i])
            : extractValuesFromDescription(articleData[i].description)

        articleData[i] = { ...articleData[i], imgSrc, h1Content, anchorHref }
      }

      setArticles([...articleData])
      setPage(0)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchSearchingArticles = async () => {
    try {
      const url = `${API_URL}/news?limit=${ARTICLES_PER_PAGE_LIMIT}&page=${
        searchingArticlesPage + 1
      }&search_query=${search}`
      const res = await fetch(url)
      const data = await res.json()

      const articleData = [...data.data]

      for (let i = 0; i < articleData.length; i++) {
        const { imgSrc, h1Content, anchorHref } =
          articleData[i].rssId === 'custom-article'
            ? extractValuesFromDescriptionCustomArticles(articleData[i])
            : extractValuesFromDescription(articleData[i].description)

        articleData[i] = { ...articleData[i], imgSrc, h1Content, anchorHref }
      }

      setSearchingArticles((prevArticles) => {
        return [...prevArticles, ...articleData]
      })

      setSearchingArticlesPage((prevPage) => prevPage + 1)
    } catch (error) {
      console.error(error)
    }
  }

  const refreshSearchingArticles = async () => {
    try {
      const url = `${API_URL}/news?limit=${ARTICLES_PER_PAGE_LIMIT}&page=${0}&search_query=${search}`
      const res = await fetch(url)
      const data = await res.json()

      const articleData = [...data.data]

      for (let i = 0; i < articleData.length; i++) {
        const { imgSrc, h1Content, anchorHref } =
          articleData[i].rssId === 'custom-article'
            ? extractValuesFromDescriptionCustomArticles(articleData[i])
            : extractValuesFromDescription(articleData[i].description)

        articleData[i] = { ...articleData[i], imgSrc, h1Content, anchorHref }
      }

      setSearchingArticles([...articleData])
      setSearchingArticlesPage(0)
    } catch (error) {
      console.error(error)
    }
  }

  const resetPage = () => {
    setPage(0)
  }

  useEffect(() => {
    if (search !== '') refreshArticles()
  }, [search])

  const value = {
    ArticlesState: {
      articles,
      setArticles,
      searchingArticles,
      setSearchingArticles,
      isLoading,
      setIsLoading,
    },
    SearchState: {
      search,
      setSearch,
    },
    CategoryState: {
      category,
      setCategory,
    },
    fetchArticles,
    refreshArticles,
    fetchSearchingArticles,
    refreshSearchingArticles,
    resetPage,
    findArticle,
  }

  return (
    <ArticleContext.Provider value={value}>{children}</ArticleContext.Provider>
  )
}
