import { useEffect, useState } from 'react'
import {
  Modal,
  View,
  Text,
  StyleSheet,
  Animated,
  ScrollView,
  Dimensions,
  Pressable,
} from 'react-native'
import Close from 'react-native-vector-icons/Fontisto'

const PrivacyModal = ({ visible, onClose }) => {
  const [fadeAnim] = useState(new Animated.Value(0))

  useEffect(() => {
    if (visible) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
      }).start()
    }
  }, [visible, fadeAnim])
  const closeModal = () => {
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => onClose())
  }

  return (
    <Modal
      animationType="fade"
      transparent
      visible={visible}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <Animated.View style={[styles.modalContent, { opacity: fadeAnim }]}>
          <Pressable style={styles.closeButton} onPress={onClose}>
            <Close name={'close-a'} size={18} color={'#000000'} />
          </Pressable>
          <ScrollView style={styles.container}>
            <Text style={styles.heading}>Privacy Policy</Text>
            <Text style={styles.section}>
              Welcome to ScrollFlip, your trusted source for the latest news and
              updates!
            </Text>
            <Text style={styles.section}>
              At ScrollFlip, we value your privacy and strive to protect your
              personal information. This Privacy Policy outlines how we collect,
              use, disclose, and safeguard your data when you use our mobile
              application.
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>1. Information We Collect</Text>
              {`
We may collect the following types of information when you use our Services:

\u2022 Personal Information: Your name, email address, and any other information you provide when registering or creating an account. 
\u2022 Usage Information: Information about how you interact with our Services, including browsing history, articles read, preferences, and interactions with advertisements. 
\u2022 Device Information: Information about the device you use to access our Services, including device type, operating system, browser type, and IP address. 
\u2022 Location Information: We may collect your general location based on your IP address or more precise location if you grant us permission.
                `}
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>
                2. How We Use Your Information
              </Text>
              {`
We use the collected information for various purposes, including but not limited to:

\u2022 Providing and improving our Services.
\u2022 Personalizing your experience and delivering relevant content.
\u2022 Sending you newsletters, updates, and promotional materials.
\u2022 Analyzing usage trends and optimizing the user experience.
\u2022 Protecting against fraudulent or unauthorized activity.`}
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>3. Sharing Your Information</Text>
              {`
We may share your information in the following circumstances:

\u2022 With third-party service providers who assist us in delivering the Services.
\u2022 With advertisers and analytics providers in aggregated, non-personally identifiable form.
\u2022 In response to legal requests, such as court orders or subpoenas, to comply with legal obligations.
\u2022 In connection with the sale, merger, or acquisition of all or a portion of our company.
`}
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>
                4. Cookies and Tracking Technologies {`\n`}
              </Text>
              We use cookies and similar tracking technologies to collect and
              store information about your usage of our Services. You can manage
              your cookie preferences through your browser settings.
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>5. Data Security</Text>
              We implement appropriate technical and organizational measures to
              protect your information from unauthorized access, loss, misuse,
              or alteration. However, no data transmission over the internet is
              completely secure, and we cannot guarantee the security of your
              information.
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>
                5. Children's Privacy {`\n`}
              </Text>
              Our Services are not intended for individuals under the age of 16.
              We do not knowingly collect personal information from children. If
              you are a parent or guardian and believe that your child has
              provided us with personal information, please contact us to
              request its deletion.
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>
                Changes to This Privacy Policy {`\n`}
              </Text>
              We may update our Privacy Policy periodically. We will notify you
              of any changes by posting the updated policy on this page.
            </Text>
            <Text style={styles.section}>
              <Text style={styles.subHeading}>Contact Us {`\n`}</Text>
              If you have any questions or concerns regarding this Privacy
              Policy, please contact us at privacy@scrollflip.com.
            </Text>
            <Text style={styles.section}>
              By using our Services, you acknowledge that you have read and
              understood this Privacy Policy. If you do not agree with our
              practices, please do not use our Services.
              {` 
              
              
              `}
            </Text>
          </ScrollView>
        </Animated.View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    width: '90%',
    borderRadius: 10,
    overflow: 'hidden',
    marginVertical: '15%',
    height: Dimensions.get('window').height * 0.8,
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: 10,
    zIndex: 1,
  },
  closeButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  container: {
    padding: 20,
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  section: {
    marginBottom: 20,
  },
  subHeading: {
    fontWeight: 'bold',
  },
})

export default PrivacyModal
