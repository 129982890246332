import {
  Image,
  ScrollView,
  Pressable,
  View,
  StyleSheet,
  Text,
} from 'react-native'
import { useEffect, useState } from 'react'
import { DrawerContentScrollView } from '@react-navigation/drawer'
import tw from 'twrnc'
import { IS_ON_DESKTOP, SCREEN_HEIGHT } from '../../utils/settings'
import { useFonts } from 'expo-font'
import Btn from '../Btn'
import { useUser } from '../../context/UserContext'
import PrivacyModal from '../Modals/PrivacyModal'

const FIRST_CATEGORY = 'Shopping' // Change if first category in order changes in App.tsx
let passedFirstCategory = false

export default function CustomDrawerContent({ navigation, state }) {
  const [isPrivacyVisible, setPrivacyVisible] = useState(false)

  const { UserState, logOut } = useUser()

  useEffect(() => {
    if (!!!document) return
    document.body.style.overflow = 'hidden'
  }, [document])

  const [fontsLoaded] = useFonts({
    'Lato-Bold': require('../../../assets/fonts/Lato-Bold.ttf'),
  })

  if (!fontsLoaded) {
    return null
  }

  let logoStyle = 'h-10 m-2 w-42'

  if (IS_ON_DESKTOP) {
    logoStyle = 'h-8 m-3 w-36'
  }

  return (
    <>
      <PrivacyModal
        visible={isPrivacyVisible}
        onClose={() => setPrivacyVisible(false)}
      />
      <DrawerContentScrollView scrollEnabled={false}>
        <Pressable
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
          onPress={() => {
            if (!!window) {
              window.location.replace('/')
            }
            navigation.navigate('Home')
          }}
        >
          <Image
            source={require('../../../assets/scrollflip-logo.png')}
            style={[
              tw`${logoStyle}`,
              {
                objectFit: 'scale-down',
              },
            ]}
          />
        </Pressable>

        <View
          style={{
            height: IS_ON_DESKTOP ? SCREEN_HEIGHT : SCREEN_HEIGHT + 125,
            backgroundColor: '#eaeaea',
            paddingBottom: 100,
          }}
        >
          <ScrollView scrollEnabled={true}>
            {state.routes.map((route) => {
              if (route.name == FIRST_CATEGORY) passedFirstCategory = true

              if (passedFirstCategory) {
                passedFirstCategory = false
                return (
                  <View key={route.key}>
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 20,
                        paddingBottom: 20,
                        borderTopWidth: IS_ON_DESKTOP ? 1 : 0,
                        borderBottomWidth: 1,
                        borderColor: 'rgba(0, 0, 0, 0.15)',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: IS_ON_DESKTOP ? 18 : 17,
                          color: 'rgba(0, 0, 0, 0.5)',
                          fontWeight: '500',
                        }}
                      >
                        Categories
                      </Text>
                    </View>
                    <Btn
                      onPress={() => navigation.navigate(route.name)}
                      style={{
                        paddingTop: 17,
                        paddingBottom: 17,
                        paddingLeft: 20,
                        paddingRight: 20,
                        borderBottomWidth: 1,
                        borderColor: 'rgba(0, 0, 0, 0.15)',
                      }}
                      textStyle={{
                        color: 'black',
                        fontFamily: 'Lato-Bold',
                        fontSize: IS_ON_DESKTOP ? 15 : 14,
                        alignSelf: 'flex-start',
                      }}
                      text={route.name}
                      color={
                        route.name === state.routes[state.index].name
                          ? '#d2cdcc'
                          : 'transparent'
                      }
                      hoverColor="#d2cdcc"
                    />
                  </View>
                )
              }

              if (
                (route.name === 'Profile' || route.name === 'Likes') &&
                UserState.user === null
              )
                return
              if (route.name === 'Log In' || route.name === 'Sign Up') return
              if (route.name === 'Search' && IS_ON_DESKTOP) return
              if (route.name === 'Newsletter') return
              if (route.name === 'CustomArticle') return

              return (
                <Btn
                  key={route.key}
                  onPress={() => navigation.navigate(route.name)}
                  style={{
                    paddingTop: 17,
                    paddingBottom: 17,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderBottomWidth: 1,
                    borderRadius: 0,
                    borderColor: 'rgba(0, 0, 0, 0.15)',
                  }}
                  textStyle={{
                    color: 'black',
                    fontFamily: 'Lato-Bold',
                    fontSize: IS_ON_DESKTOP ? 15 : 14,
                    alignSelf: 'flex-start',
                  }}
                  text={route.name}
                  color={
                    route.name === state.routes[state.index].name
                      ? '#d2cdcc'
                      : 'transparent'
                  }
                  hoverColor="#d2cdcc"
                />
              )
            })}
          </ScrollView>
          <View style={styles.parentView}>
            <View style={styles.linkContainer}>
              {UserState.user !== null ? (
                <>
                  <Pressable onPress={() => logOut(navigation)}>
                    <Text style={styles.link}>Log Out</Text>
                  </Pressable>
                </>
              ) : (
                <>
                  <Pressable onPress={() => navigation.navigate('Log In')}>
                    <Text style={styles.link}>Login</Text>
                  </Pressable>
                  <Pressable onPress={() => navigation.navigate('Sign Up')}>
                    <Text style={styles.link}>Sign Up</Text>
                  </Pressable>
                </>
              )}
              <Pressable onPress={() => navigation.navigate('Newsletter')}>
                <Text style={styles.link}>Newsletter</Text>
              </Pressable>
            </View>
            <View style={styles.linkContainer}>
              <Pressable onPress={() => setPrivacyVisible(true)}>
                <Text style={styles.link}>Privacy Policy</Text>
              </Pressable>
              <Pressable>
                <Text style={styles.link}>© 2023 ScrollFlip</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </DrawerContentScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignSelf: 'center',
  },
  link: {
    textDecorationLine: 'underline',
    paddingHorizontal: 20,
    paddingVertical: 15,
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: IS_ON_DESKTOP ? 15 : 14,
  },
  parentView: {
    marginBottom: IS_ON_DESKTOP ? 0 : 50,
    overflow: 'hidden',
    borderTopWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.15)',
  },
})
