import { Text, View, ImageBackground, Linking, Animated } from 'react-native'
import tw from 'twrnc'
import { useFonts } from 'expo-font'
import { LinearGradient } from 'expo-linear-gradient'
import { IS_ON_DESKTOP, SCREEN_HEIGHT } from '../../utils/settings'
import Btn from '../Btn'
import LikeButton from './LikeButton'
import { useEffect, useState } from 'react'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { type Article } from '../../types/articles'

type ArticleProps = {
  article: Article
}

export default function Article({ article }: ArticleProps) {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()
  const [fadeAnim] = useState(new Animated.Value(0))

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }, [])

  const [fontsLoaded] = useFonts({
    'Lato-Light': require('../../../assets/fonts/Lato-Light.ttf'),
    'Lato-Regular': require('../../../assets/fonts/Lato-Regular.ttf'),
    'Lato-Bold': require('../../../assets/fonts/Lato-Bold.ttf'),
  })

  if (!fontsLoaded) {
    return <></>
  }

  return (
    <Animated.View
      key={article.title}
      style={{
        width: '100%',
        height: IS_ON_DESKTOP ? 'auto' : SCREEN_HEIGHT,
        paddingBottom: IS_ON_DESKTOP ? 50 : 0,
        opacity: fadeAnim,
      }}
    >
      <ImageBackground
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          height: IS_ON_DESKTOP
            ? (SCREEN_HEIGHT * 2) / 4
            : (SCREEN_HEIGHT * 2) / 3,
        }}
        source={{
          uri: article.imgSrc,
        }}
        imageStyle={{ borderRadius: 2 }}
      >
        <LinearGradient
          colors={[
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0.7)',
            'rgba(255, 255, 255, 0.9)',
            'rgba(255, 255, 255, 1)',
          ]}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '35%', // Adjust the height of the gradient overlay
          }}
        />
        <Text
          style={[tw`mx-5 text-2xl font-bold`, { fontFamily: 'Lato-Bold' }]}
          numberOfLines={7}
        >
          {article.title}
        </Text>
      </ImageBackground>
      <View
        style={{
          width: '100%',
        }}
      >
        <Text
          style={[
            tw`mx-5 mt-3 ${IS_ON_DESKTOP ? 'text-lg' : 'text-sm'}`,
            {
              fontFamily: IS_ON_DESKTOP ? 'Lato-Regular' : 'Lato-Light',
              opacity: 0.75,
            },
          ]}
          numberOfLines={4}
        >
          {article.rssId === 'custom-article'
            ? article.description
            : article.h1Content}
        </Text>

        <Text
          style={[
            tw`mx-5 mt-3 mb-1 ${IS_ON_DESKTOP ? 'text-lg' : 'text-base'}`,
            { opacity: 0.85 },
          ]}
        >
          From:{' '}
          <Text style={[tw`font-black`, { color: '#E01E26' }]}>
            {article.source}
          </Text>
        </Text>
        <View
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <View
            style={[
              tw`mx-5 rounded-sm`,
              {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              },
            ]}
          >
            <Btn
              onPress={() => {
                if (article.rssId === 'custom-article') {
                  navigation.navigate('CustomArticle', { article })
                  return
                }

                Linking.openURL(article.anchorHref)
              }}
              text="Read More"
              color="#E01E26"
              hoverColor="#b0131a"
            />
          </View>
          <LikeButton article={article} />
        </View>
      </View>
    </Animated.View>
  )
}
