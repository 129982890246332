import { useEffect, useRef, useState } from 'react'
import {
  Pressable,
  Dimensions,
  View,
  Text,
  SafeAreaView,
  ActivityIndicator,
  Animated,
} from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import tw from 'twrnc'
import { useArticles } from '../context/ArticleContext'
import { IS_ON_DESKTOP, SCREEN_HEIGHT, IS_ON_WEB } from '../utils/settings'
import NoResults from '../components/Articles/NoResults'
import Icon from 'react-native-vector-icons/FontAwesome'
import ArticlesListWeb from '../components/Articles/ArticlesListWeb'
import ArticlesList from '../components/Articles/ArticlesList'
import Footer from '../components/Layout/Footer'

export default function SearchScreen({ navigation }) {
  const {
    SearchState,
    ArticlesState,
    refreshSearchingArticles,
    fetchSearchingArticles,
  } = useArticles()
  const [searchInput, setSearchInput] = useState<string>(SearchState.search)
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [isSearchBarVisible, setSearchBarVisible] = useState(true)
  const prevScrollY = useRef(0)
  const [yAnimation, _] = useState(new Animated.Value(0))

  const resetSearchInput = () => setSearchInput('')

  const onRefresh = () => {
    setRefreshing(true)
    setTimeout(async () => {
      await refreshSearchingArticles()
      setRefreshing(false)
    }, 1000)
  }

  const submitSearch = () => {
    SearchState.setSearch(searchInput.toLocaleLowerCase())
  }

  const handleScroll = (event) => {
    const currentScrollY = IS_ON_WEB
      ? event.target.scrollTop
      : event.nativeEvent.contentOffset.y
    const offset = IS_ON_WEB ? -200 : 20

    if (currentScrollY > prevScrollY.current + offset && currentScrollY > 200) {
      // Scrolling down
      if (isSearchBarVisible) {
        setSearchBarVisible(false)
      }
    } else if (
      currentScrollY < prevScrollY.current - offset ||
      currentScrollY <= 200
    ) {
      // Scrolling up
      if (!isSearchBarVisible) {
        setSearchBarVisible(true)
      }
    }

    prevScrollY.current = currentScrollY
  }

  useEffect(() => {
    Animated.timing(yAnimation, {
      toValue: isSearchBarVisible ? 0 : -50,
      duration: 100,
      useNativeDriver: false,
    }).start()
  }, [isSearchBarVisible])

  return (
    <View style={[tw`bg-white`, { flex: 1 }]}>
      <Animated.View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          flex: 1,
          transform: [{ translateY: yAnimation }],
        }}
      >
        {!IS_ON_DESKTOP && (
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextInput
                onChangeText={(text) => setSearchInput(text)}
                value={searchInput}
                onEndEditing={submitSearch}
                style={{
                  paddingVertical: 8,
                  paddingHorizontal: 40,
                  margin: 5,
                  marginLeft: 5,
                  borderRadius: 40,
                  width: Dimensions.get('screen').width - 50,
                  backgroundColor: '#f2f2f2',
                  fontSize: 14,
                }}
                placeholder="Search here..."
                placeholderTextColor="gray"
              />

              <View style={{ position: 'absolute', top: 14, left: 20 }}>
                <Icon name="search" size={15} color="gray" />
              </View>
            </View>

            <Pressable
              onPress={submitSearch}
              style={{
                backgroundColor: '#144270',
                borderRadius: 40,
                margin: 5,
                paddingVertical: 8,
                paddingHorizontal: 20,
                position: 'absolute',
                right: 0,
              }}
            >
              <Text style={{ color: 'white', fontSize: 14 }}>Search</Text>
            </Pressable>
          </View>
        )}

        <SafeAreaView
          style={{
            flex: 1,
            height: SCREEN_HEIGHT,
            marginBottom: -48,
          }}
        >
          {ArticlesState.isLoading ? (
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: SCREEN_HEIGHT,
              }}
            >
              <ActivityIndicator size="large" />
            </View>
          ) : ArticlesState.searchingArticles.length === 0 &&
            SearchState.search !== '' ? (
            <NoResults />
          ) : IS_ON_WEB ? (
            <ArticlesListWeb
              articles={ArticlesState.searchingArticles}
              fetchArticles={fetchSearchingArticles}
              onScroll={handleScroll}
            />
          ) : (
            <ArticlesList
              articles={ArticlesState.searchingArticles}
              refreshing={refreshing}
              onRefresh={onRefresh}
              fetchArticles={fetchSearchingArticles}
              onScroll={handleScroll}
            />
          )}
        </SafeAreaView>
      </Animated.View>
      {IS_ON_WEB ? (
        <></>
      ) : (
        <Footer
          navigation={navigation}
          resetSearchInput={resetSearchInput}
          screen="Search"
        />
      )}
    </View>
  )
}
