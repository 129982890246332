import { useState } from 'react'
import { View, Pressable, Text } from 'react-native'
import { type UserWishListedProduct } from '../../types/users'
import Icon from 'react-native-vector-icons/FontAwesome'

type AddOrRemoveToWishlistButtonProps = {
  product: UserWishListedProduct
  userWishlistedProducts: UserWishListedProduct[]
  addProductToWishlist: (product: UserWishListedProduct) => void
  removeProductFromWishlist: (product: UserWishListedProduct) => void
}

export default function AddOrRemoveToWhishlistButton({
  product,
  userWishlistedProducts,
  addProductToWishlist,
  removeProductFromWishlist,
}: AddOrRemoveToWishlistButtonProps) {
  let isProductWishlisted = false
  userWishlistedProducts.forEach((item) => {
    if (item.name === product.name) isProductWishlisted = true
  })

  const [isWishlisted, setIsWishlisted] = useState(!isProductWishlisted)

  const toggleSwitch = () => {
    setIsWishlisted((previousState) => !previousState)

    if (isWishlisted) {
      addProductToWishlist(product)
      return
    }

    removeProductFromWishlist(product)
  }

  return (
    <Pressable
      onPress={toggleSwitch}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: 10,
        height: 45,
        borderRadius: 2,
        backgroundColor: isWishlisted ? 'white' : '#144270',
        borderWidth: 1,
        borderColor: '#144270',
        width: isWishlisted ? 160 : 210,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 5,
          paddingLeft: 10,
        }}
      >
        <Icon
          name={!isWishlisted ? 'heart' : 'heart-o'}
          size={25}
          color={'#000000'}
          style={[{ color: !isWishlisted ? 'white' : '#144270' }]}
        />
        <Text
          style={{
            color: isWishlisted ? '#144270' : 'white',
            paddingLeft: 6,
            paddingTop: 4,
            fontSize: 15,
          }}
        >
          {isWishlisted ? 'Add to Wishlist' : 'Remove From Wishlist'}
        </Text>
      </View>
    </Pressable>
  )
}
