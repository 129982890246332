import Icon from 'react-native-vector-icons/Fontisto'
import DelButton from 'react-native-vector-icons/Feather'
import { IS_ON_DESKTOP } from '../../utils/settings'
import {
  View,
  Pressable,
  Dimensions,
  Text,
  FlatList,
  Linking,
  Image,
} from 'react-native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { useUser } from '../../context/UserContext'
import { useState } from 'react'

export default function DesktopNavbar() {
  const [isNavEnabled, setIsNavEnabled] = useState(false)
  const toggleNav = () => setIsNavEnabled((prevState) => !prevState)
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()

  const redirectToUrl = (url: string) => {
    navigation.navigate(url)
    setIsNavEnabled(false)
  }

  return (
    <>
      {/* Burger icon  */}
      <Pressable
        accessible
        role="button"
        android_ripple={{ borderless: true }}
        style={{ marginHorizontal: 11 }}
        onPress={() => toggleNav()}
      >
        {!isNavEnabled ? (
          <Image
            style={{
              height: 18,
              width: 18,
            }}
            resizeMode={'cover'}
            source={require('../../../assets/burgeri.png')}
            fadeDuration={0}
          />
        ) : (
          <Icon name={'close-a'} size={18} color={'#878787'} />
        )}
      </Pressable>

      {/* Desktop Nav */}
      {isNavEnabled && (
        <div
          style={{
            position: 'absolute',
            top: 46,
            backgroundColor: '#faf9f7',
            width: 'auto',
            zIndex: 100,
            filter:
              ' drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              padding: 20,
              gap: 40,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  marginVertical: 10,
                }}
              >
                Categories
              </Text>
              <Pressable onPress={() => redirectToUrl('Shopping')}>
                <Text style={{ fontSize: 15 }}>Shopping</Text>
              </Pressable>
              <Pressable onPress={() => redirectToUrl('Internet Finds')}>
                <Text style={{ fontSize: 15 }}>Internet Finds</Text>
              </Pressable>
              <Pressable onPress={() => redirectToUrl('TVAndMovies')}>
                <Text style={{ fontSize: 15 }}>TV and Movies</Text>
              </Pressable>
              <Pressable onPress={() => redirectToUrl('Celebrity')}>
                <Text style={{ fontSize: 15 }}>Celebrity</Text>
              </Pressable>
              <Pressable onPress={() => redirectToUrl('Sex & Love')}>
                <Text style={{ fontSize: 15 }}>Sex & Love</Text>
              </Pressable>
              <Pressable onPress={() => redirectToUrl('Community')}>
                <Text style={{ fontSize: 15 }}>Community</Text>
              </Pressable>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    marginVertical: 10,
                  }}
                >
                  About
                </Text>
                <Pressable onPress={() => redirectToUrl('Newsletter')}>
                  <Text style={{ fontSize: 15 }}>Newsletter</Text>
                </Pressable>
                <Pressable onPress={() => redirectToUrl('Privacy Policy')}>
                  <Text style={{ fontSize: 15 }}>Privacy Policy</Text>
                </Pressable>
                <Pressable onPress={() => redirectToUrl('@2023 ScrollFlip')}>
                  <Text style={{ fontSize: 15 }}>@2023 ScrollFlip</Text>
                </Pressable>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: 'bold',
                    marginVertical: 10,
                  }}
                >
                  Account
                </Text>
                <Text style={{ fontSize: 15 }}>Profile</Text>
                <Text style={{ fontSize: 15 }}>Likes</Text>
                <Text style={{ fontSize: 15 }}>@2023 Scrollflip</Text>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              gap: 6,
              paddingTop: 10,
              paddingBottom: 20,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <Text style={{ fontWeight: 'bold' }}>@2023 Scrollflip</Text>
            <Text>Press</Text>
            <Text>Privacy</Text>
            <Text>Terms</Text>
            <Text>Help</Text>
            <Text>Contact</Text>
          </div>
        </div>
      )}
    </>
  )
}
