import { DrawerNavigationProp } from '@react-navigation/drawer'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { Pressable, Text, View, StyleSheet, Dimensions } from 'react-native'
import { useUser } from '../../context/UserContext'
import { IS_ON_DESKTOP } from '../../utils/settings'
import Btn from '../Btn'

export default function AuthBtns() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()
  const { UserState, logOut } = useUser()

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {UserState.user ? (
        <>
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginHorizontal: 5,
            }}
            onPress={() => {
              logOut(navigation)
            }}
          >
            <Text
              style={{
                color: 'black',
                fontSize: 15,
                fontWeight: 'bold',
              }}
            >
              Log Out
            </Text>
          </Pressable>
        </>
      ) : (
        <>
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginHorizontal: 5,
            }}
            onPress={() => navigation.navigate('Log In')}
          >
            <Text
              style={{
                color: 'black',
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              Log In
            </Text>
          </Pressable>
          <Btn
            style={{
              paddingHorizontal: 15,
              paddingBottom: 7,
              paddingTop: 7,
              borderRadius: 50,
              marginLeft: 10,
              // marginRight: IS_ON_DESKTOP
              //   ? Dimensions.get('window').width / 10
              //   : 0,
              borderColor: '#144270',
              borderWidth: 2,
            }}
            onPress={() => navigation.navigate('Sign Up')}
            textStyle={{
              color: 'white',
              fontSize: 14,
              fontWeight: 'bold',
            }}
            text="Sign Up"
          />
        </>
      )}
    </View>
  )
}
