import { Pressable, Text } from 'react-native'
import { type DrawerNavigationProp } from '@react-navigation/drawer'
import { type ParamListBase, useNavigation } from '@react-navigation/native'

export default function NavbarProfileBtn() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()

  return (
    <Pressable onPress={() => navigation.navigate('Profile')}>
      <Text
        style={{
          fontWeight: 'bold',
        }}
      >
        Profile
      </Text>
    </Pressable>
  )
}
