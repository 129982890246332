import { Pressable, Text } from 'react-native'
import { type DrawerNavigationProp } from '@react-navigation/drawer'
import { type ParamListBase, useNavigation } from '@react-navigation/native'

export default function NavbarFavoriteArticlesBtn() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()

  return (
    <Pressable onPress={() => navigation.navigate('Likes')}>
      <Text
        style={{
          fontWeight: 'bold',
        }}
      >
        Favorite Articles
      </Text>
    </Pressable>
  )
}
