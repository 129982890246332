import Icon from 'react-native-vector-icons/Fontisto'
import DelButton from 'react-native-vector-icons/Feather'
import { IS_ON_DESKTOP } from '../../utils/settings'
import {
  View,
  Pressable,
  Dimensions,
  Text,
  FlatList,
  Linking,
  Image,
} from 'react-native'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { useUser } from '../../context/UserContext'

export default function WishList() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()
  const {
    UserState,
    userWishlistedProducts,
    isEnabledWishlist,
    setIsEnabledWishlist,
    removeProductFromWishlist,
  } = useUser()

  return !isEnabledWishlist ? (
    <></>
  ) : (
    <View
      style={{
        position: 'absolute',
        width: IS_ON_DESKTOP ? 350 : Dimensions.get('screen').width,
        top: 60,
        left: IS_ON_DESKTOP ? '67%' : 0,
        zIndex: 100,
        backgroundColor: '#faf9f7',
        borderRadius: 2,
        padding: 20,
        shadowColor: 'black',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.2,
        shadowRadius: 4,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20,
          }}
        >
          <Text style={{ fontSize: 18, fontWeight: 'bold' }}>
            Your Wishlist
          </Text>
          <Text>You have {userWishlistedProducts.length} listed products</Text>
        </View>

        <Pressable onPress={() => setIsEnabledWishlist(false)}>
          <Icon
            name={'close-a'}
            style={{ marginTop: 10 }}
            size={18}
            color={'#000000'}
          />
        </Pressable>
      </View>
      <FlatList
        style={{ height: IS_ON_DESKTOP ? 250 : 200 }}
        data={userWishlistedProducts}
        renderItem={({ item }) => (
          <Pressable onPress={() => Linking.openURL(item.redirectUrl)}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                borderWidth: 1,
                borderColor: 'rgba(0, 0, 0, 0.1)',
                padding: 5,
                marginBottom: 5,
                borderRadius: 5,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Image
                  source={{ uri: item.imageUrl }}
                  alt={item.name}
                  style={{
                    width: 70,
                    height: 70,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                  resizeMode={'contain'}
                />
                <View>
                  <Text>
                    {item.name.length > 30
                      ? item.name.substring(0, 30 - 3) + '...'
                      : item.name}
                  </Text>
                  <Text style={{ fontWeight: 'bold', marginTop: 5 }}>
                    ${item.price}{' '}
                    <Text
                      style={{
                        fontWeight: 'normal',
                        color: 'rgba(0, 0, 0, 0.5)',
                        marginLeft: 4,
                      }}
                    >
                      on amazon
                    </Text>
                  </Text>
                </View>
              </View>
              {UserState.user && (
                <Pressable onPress={() => removeProductFromWishlist(item)}>
                  <View
                    style={{
                      height: 30,
                      width: 30,
                      marginRight: 10,
                      borderRadius: 5,
                      backgroundColor: '#282828',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <DelButton name={'trash-2'} size={18} color={'white'} />
                  </View>
                </Pressable>
              )}
            </View>
          </Pressable>
        )}
      />

      {!UserState.user && (
        <View
          style={{
            padding: 10,
            backgroundColor: 'white',
            top: 20,
            shadowColor: 'rgba(99, 99, 99, 0.2)',
            shadowOffset: { width: 2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
            marginBottom: 20,
          }}
        >
          <Image
            source={require('/assets/wishlist-heart.png')}
            alt="image"
            style={{
              width: 40,
              height: 40,
              marginTop: 5,
              marginBottom: 5,

              marginHorizontal: 'auto',
            }}
            resizeMode={'contain'}
          />

          <Text style={{ textAlign: 'center', marginVertical: 10 }}>
            Sign in to permanently save products, edit, and <br />
            access your full wishlist from any device.
          </Text>
          <Pressable onPress={() => navigation.navigate('Sign Up')}>
            <View>
              <Text
                style={{
                  textAlign: 'center',
                  backgroundColor: 'white',
                  borderWidth: 1,
                  borderColor: '#e01e27',
                  color: '#e01e27',
                  marginTop: 10,
                  paddingVertical: 7,
                  borderRadius: 5,
                  fontWeight: 'bold',
                }}
              >
                Sign in or create an account
              </Text>
            </View>
          </Pressable>
        </View>
      )}
    </View>
  )
}
