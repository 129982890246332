import { Dimensions, Platform } from 'react-native'

export const ARTICLES_PER_PAGE_LIMIT = 6

// export const API_URL = 'http://localhost:3000/api'
// export const API_URL = 'http://192.168.1.11:3000/api'
export const API_URL = 'https://scrollflip.up.railway.app/api'

export const IS_ON_DESKTOP =
  Platform.OS === 'web' && Dimensions.get('window').width >= 786

export const IS_ON_WEB = Platform.OS === 'web'

export const SCREEN_HEIGHT = IS_ON_WEB
  ? Dimensions.get('window').height - 60
  : Dimensions.get('window').height - 125
