import { useFonts } from 'expo-font'
import { View } from 'react-native'
import Footer from '../components/Layout/Footer'
import NewsletterSubscription from '../components/NewsletterSubscription'
import { IS_ON_WEB } from '../utils/settings'

export default function NewsletterScreen({ navigation }) {
  let [fontsLoaded] = useFonts({
    'Lato-Bold': require('../../assets/fonts/Lato-Bold.ttf'),
  })

  if (!fontsLoaded) {
    return null
  }

  return (
    <View
      style={{
        backgroundColor: 'white',
        flex: 1,
        paddingTop: 80,
        paddingBottom: 200,
      }}
    >
      <NewsletterSubscription />
      {IS_ON_WEB ? <></> : <Footer navigation={navigation} screen="Home" />}
    </View>
  )
}
