import { Dimensions, Platform, Pressable, Text, View } from 'react-native'
import { NavigationContainer } from '@react-navigation/native'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { IS_ON_DESKTOP } from './src/utils/settings'

/** Components */
import FlashMessage from 'react-native-flash-message'
import CustomDrawerContent from './src/components/Layout/CustomDrawerContent'
import HeaderLogo from './src/components/Layout/HeaderLogo'
import WishListButton from './src/components/Layout/WishListButton'
import AuthBtns from './src/components/Layout/AuthBtns'
import DesktopSearchBar from './src/components/Layout/DesktopSearchBar'
import DrawerToggleBtn from './src/components/Layout/DrawerToggleBtn'
import DesktopNavbar from './src/components/Layout/DesktopNavbar'

/** Ctx Providers */
import UserProvider from './src/context/UserContext'
import ArticleProvider from './src/context/ArticleContext'

/** Screens */
import ArticlesScreen from './src/screens/ArticlesScreen'
import SearchScreen from './src/screens/SearchScreen'
import ProfileScreen from './src/screens/ProfileScreen'
import SignupScreen from './src/screens/SignupScreen'
import LoginScreen from './src/screens/LoginScreen'
import LikesScreen from './src/screens/LikesScreen'
import NewsLetterScreen from './src/screens/NewsLetterScreen'
import CustomArticleScreen from './src/screens/CustomArticleScreen'
import WishList from './src/components/Layout/WishList'
import AsyncStorage from '@react-native-async-storage/async-storage'
import NavbarProfileBtn from './src/components/Layout/NavbarProfileBtn'
import NavbarFavoriteArticlesBtn from './src/components/Layout/NavbarFavoriteArticlesBtn'

export const headerHeight = Platform.OS === 'ios' ? 100 : 60

export default function App() {
  const options = {
    headerLeft: () => {
      return IS_ON_DESKTOP ? <></> : <HeaderLogo />
    },
    headerTitleStyle: {
      color: 'transparent',
    },
    headerRight: () => {
      return IS_ON_DESKTOP ? (
        <div
          style={{
            width: Dimensions.get('window').width,
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'normal',
          }}
        >
          <div
            style={{
              width: '30%',
            }}
          ></div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <DesktopNavbar />
              <HeaderLogo />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                margin: 0,
                gap: 30,
              }}
            >
              <DesktopSearchBar />

              {!!localStorage.getItem('user') && <NavbarProfileBtn />}
              {!!localStorage.getItem('user') && <NavbarFavoriteArticlesBtn />}

              <WishListButton />
              <AuthBtns />
            </div>
          </div>

          <div
            style={{
              width: '30%',
            }}
          ></div>
        </div>
      ) : (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: IS_ON_DESKTOP ? 0 : 20,
            alignItems: 'center',
            justifyContent: 'center',
            width: IS_ON_DESKTOP ? '100%' : 'auto',
          }}
        >
          <WishListButton />
          <DrawerToggleBtn />
        </View>
      )
    },
    headerStyle: {
      height: headerHeight,
    },
  }

  const Drawer = createDrawerNavigator()

  return (
    <UserProvider>
      <FlashMessage
        position={'top'}
        style={{
          width: Dimensions.get('window').width,
        }}
      />
      <ArticleProvider>
        <NavigationContainer>
          <WishList />
          <Drawer.Navigator
            initialRouteName="Home"
            drawerContent={(props) => <CustomDrawerContent {...props} />}
            screenOptions={{
              drawerType: 'front',
            }}
          >
            <Drawer.Screen
              name="Home"
              component={ArticlesScreen}
              initialParams={{ category: '' }}
              options={{ ...options, title: 'Home' }}
            />
            <Drawer.Screen
              name="Search"
              component={SearchScreen}
              options={{ ...options, title: 'Search' }}
            />
            <Drawer.Screen
              name="Profile"
              component={ProfileScreen}
              options={{ ...options, title: 'Profile' }}
            />
            <Drawer.Screen
              name="CustomArticle"
              component={CustomArticleScreen}
              options={{ ...options, title: 'Article' }}
            />
            <Drawer.Screen
              name="Newsletter"
              component={NewsLetterScreen}
              options={{ ...options, title: 'Newsletter' }}
            />
            <Drawer.Screen
              name="Sign Up"
              component={SignupScreen}
              options={{ ...options, title: 'Sign Up' }}
            />
            <Drawer.Screen
              name="Log In"
              component={LoginScreen}
              options={{ ...options, title: 'Log In' }}
            />
            <Drawer.Screen
              name="Likes"
              component={LikesScreen}
              options={{ ...options, title: 'Favorite Articles' }}
            />

            {/* Categories -- Start */}
            <Drawer.Screen
              name="Shopping"
              component={ArticlesScreen}
              initialParams={{ category: 'Shopping' }}
              options={{ ...options, title: 'Shopping' }}
            />
            <Drawer.Screen
              name="Internet Finds"
              component={ArticlesScreen}
              initialParams={{ category: 'Internet Finds' }}
              options={{ ...options, title: 'Internet Finds' }}
            />
            <Drawer.Screen
              name="TVAndMovies"
              component={ArticlesScreen}
              initialParams={{ category: 'TVAndMovies' }}
              options={{ ...options, title: 'TV And Movies' }}
            />
            <Drawer.Screen
              name="Celebrity"
              component={ArticlesScreen}
              initialParams={{ category: 'Celebrity' }}
              options={{ ...options, title: 'Celebrity' }}
            />
            <Drawer.Screen
              name="Sex & Love"
              component={ArticlesScreen}
              initialParams={{ category: 'Sex & Love' }}
              options={{ ...options, title: 'Sex & Love' }}
            />
            <Drawer.Screen
              name="Community"
              component={ArticlesScreen}
              initialParams={{ category: 'Community' }}
              options={{ ...options, title: 'Community' }}
            />
            {/* Categories -- End */}
          </Drawer.Navigator>
        </NavigationContainer>
      </ArticleProvider>
    </UserProvider>
  )
}
