import { Dimensions, FlatList, RefreshControl } from 'react-native'
import { type Article as ArticleType } from '../../types/articles'
import { IS_ON_DESKTOP, SCREEN_HEIGHT } from '../../utils/settings'
import Article from './Article'

type ArticlesListProps = {
  articles: ArticleType[]
  refreshing: any
  onRefresh: any
  fetchArticles: () => Promise<void>
  onScroll?: any
}

export default function ArticlesList({
  articles,
  refreshing,
  onRefresh,
  fetchArticles,
  onScroll = null,
}: ArticlesListProps) {
  return (
    <FlatList
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      data={articles}
      renderItem={({ item }) => <Article article={item} key={item._id} />}
      onEndReached={fetchArticles}
      onEndReachedThreshold={0.3}
      horizontal={false}
      decelerationRate={0}
      snapToInterval={SCREEN_HEIGHT}
      snapToAlignment={'center'}
      showsVerticalScrollIndicator={false}
      onScroll={(e) => {
        if (onScroll) onScroll(e)
      }}
      style={{
        paddingHorizontal: IS_ON_DESKTOP
          ? Dimensions.get('window').width / 12
          : 0,
      }}
    />
  )
}
