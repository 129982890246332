import { View, Text, ImageBackground, Linking, Dimensions } from 'react-native'
import { IS_ON_DESKTOP, IS_ON_WEB, SCREEN_HEIGHT } from '../utils/settings'
import { useUser } from '../context/UserContext'
import tw from 'twrnc'
import { LinearGradient } from 'expo-linear-gradient'
import Btn from '../components/Btn'
import LikeButton from '../components/Articles/LikeButton'
import Footer from '../components/Layout/Footer'
import { Article } from '../types/articles'

type LikedArticleProps = {
  article: Article
  navigation: any
}

function LikedArticle({ article, navigation }: LikedArticleProps) {
  const articleImg = !!article.imgSrc
    ? article.imgSrc
    : article.thumbnailLink.slice(0, article.thumbnailLink.length - 1)

  // console.log({ articlelink: `https://` })

  return (
    <View
      style={{
        height: SCREEN_HEIGHT / 2.25,
        width: IS_ON_DESKTOP
          ? Dimensions.get('window').width / 4.7
          : Dimensions.get('window').width / 2.3,
        backgroundColor: 'gray',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ImageBackground
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          height: SCREEN_HEIGHT / 2.25,
          width: '100%',
        }}
        source={{
          uri: articleImg,
        }}
        imageStyle={{ borderRadius: 6 }}
      >
        <LinearGradient
          colors={[
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0.7)',
            'rgba(255, 255, 255, 0.9)',
            'rgba(255, 255, 255, 1)',
          ]}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50%',
            width: '100%',
          }}
        />
        <Text
          style={[
            tw`font-bold`,
            {
              fontFamily: 'Lato-Bold',
              fontSize: IS_ON_DESKTOP ? 18 : 14,
              marginHorizontal: IS_ON_DESKTOP ? 12 : 4,
              marginVertical: IS_ON_DESKTOP ? 5 : 2,
            },
          ]}
          numberOfLines={3}
        >
          {article.title}
        </Text>
        <View
          style={[
            tw`px-1`,
            {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'row',
              marginBottom: 10,
            },
          ]}
        >
          <View
            style={[
              tw`rounded-sm `,
              {
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              },
            ]}
          >
            <Btn
              onPress={() => {
                if (!!!article.imgSrc) {
                  navigation.navigate('CustomArticle', { article })
                  return
                }

                Linking.openURL(article.anchorHref)
              }}
              text="Read More"
              color="#E01E26"
              hoverColor="#b0131a"
              style={{
                padding: IS_ON_DESKTOP ? 11 : 6,
                marginTop: 10,
                marginLeft: 5,
                marginBotton: 5,
              }}
              textStyle={{ fontSize: IS_ON_DESKTOP ? 14 : 12 }}
            />
          </View>
          <LikeButton article={article} />
        </View>
      </ImageBackground>
    </View>
  )
}

export default function LikesScreen({ navigation }) {
  const { userLikedArticles } = useUser()

  const rowItemsCount = IS_ON_DESKTOP ? 3 : 2
  const chunks = []
  for (let i = 0; i < userLikedArticles.length; i += rowItemsCount) {
    chunks.push(userLikedArticles.slice(i, i + rowItemsCount))
  }

  return (
    <View
      style={[
        tw`bg-white`,
        {
          flex: 1,
          overflowY: 'scroll',
          alignItems: 'center',
          overflow: 'hidden',
          justifyContent: 'start',
          height: Dimensions.get('window').height,
          paddingTop: 15,
          paddingLeft: IS_ON_DESKTOP ? Dimensions.get('window').width / 7.5 : 0,
          paddingRight: IS_ON_DESKTOP
            ? Dimensions.get('window').width / 7.5
            : 0,
          paddingBottom: 200,
        },
      ]}
    >
      <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        {userLikedArticles.length !== 0 ? (
          chunks.map((articles, index) => (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
              }}
              key={index}
            >
              {articles.map((article: Article, index2) => {
                return !!article ? (
                  <LikedArticle
                    key={article._id}
                    article={article}
                    navigation={navigation}
                  />
                ) : (
                  <View
                    style={{
                      height: SCREEN_HEIGHT / 2.25,
                      width: IS_ON_DESKTOP
                        ? '30%'
                        : Dimensions.get('window').width / 2.3,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    key={`${index}${index2}`}
                  >
                    <Text>Article has been deleted</Text>
                  </View>
                )
              })}
            </View>
          ))
        ) : (
          <Text
            style={{
              textAlign: 'center',
              fontSize: 16,
              margin: 20,
              fontWeight: '400',
            }}
          >
            You haven't liked any articles yet. <br />
            Start exploring and saving your favorite articles to your wishlist!
          </Text>
        )}
      </View>
      {IS_ON_WEB ? <></> : <Footer navigation={navigation} screen="Profile" />}
    </View>
  )
}
