import { DrawerNavigationProp } from '@react-navigation/drawer'
import {
  DrawerActions,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native'
import { Pressable, Platform, Image, Dimensions } from 'react-native'
import { IS_ON_DESKTOP } from '../../utils/settings'

export default function DrawerToggleBtn() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()

  return (
    <Pressable
      accessible
      role="button"
      android_ripple={{ borderless: true }}
      onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
      style={{ marginHorizontal: 11 }}
      hitSlop={Platform.select({
        default: { top: 16, right: 16, bottom: 16, left: 16 },
      })}
    >
      <Image
        style={{
          height: IS_ON_DESKTOP ? 25 : 20,
          width: IS_ON_DESKTOP ? 25 : 20,
          marginRight: IS_ON_DESKTOP ? Dimensions.get('window').width / 10 : 20,
        }}
        resizeMode={'cover'}
        source={require('../../../assets/burgeri.png')}
        fadeDuration={0}
      />
    </Pressable>
  )
}
