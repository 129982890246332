import { DrawerNavigationProp } from '@react-navigation/drawer'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import { Dimensions, Image, Pressable } from 'react-native'
import tw from 'twrnc'
import { IS_ON_DESKTOP } from '../../utils/settings'

export default function HeaderLogo() {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>()

  let logoStyle = 'h-8 ml-3 w-28'

  if (IS_ON_DESKTOP) {
    logoStyle = 'h-9 w-30'
  }

  return (
    <Pressable
      onPress={() => {
        if (!!window) {
          window.location.replace('/')
        }
        navigation.navigate('Home')
      }}
    >
      <Image
        source={require('../../../assets/scrollflip-logo.png')}
        style={[
          tw`${logoStyle}`,
          {
            marginLeft: IS_ON_DESKTOP ? 0 : 20,
          },
        ]}
      ></Image>
    </Pressable>
  )
}
