import {
  createContext,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from 'react'
import { API_URL } from '../utils/settings'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  extractValuesFromDescription,
  extractValuesFromDescriptionCustomArticles,
} from '../utils/article'
import {
  type LikeArticleParams,
  type LogInCredentials,
  type SignUpCredentials,
  type User,
  type UserContextData,
  type UserWishListedProduct,
} from '../types/users'
import { UserLikedArticle } from '../types/articles'

const UserContext = createContext<UserContextData>(undefined)

export function useUser() {
  return useContext(UserContext)
}

export default function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [token, setToken] = useState<string | null>(null)
  const [userLikedArticles, setUserLikedArticles] = useState<
    UserLikedArticle[]
  >([])
  const [userWishlistedProducts, setUserWishlistedProducts] = useState<
    UserWishListedProduct[]
  >([])
  const [isEnabledWishlist, setIsEnabledWishlist] = useState(false)

  useEffect(() => {
    const updateUserData = async () => {
      const userFromStorageJson = await AsyncStorage.getItem('user')
      const tokenFromStorageJson = await AsyncStorage.getItem('token')

      if (!!!userFromStorageJson || userFromStorageJson === 'undefined') return
      const userFromStorage = JSON.parse(userFromStorageJson)

      if (!!!tokenFromStorageJson || tokenFromStorageJson === 'undefined')
        return
      const tokenFromStorage = JSON.parse(tokenFromStorageJson)

      if (userFromStorage === null || tokenFromStorage === null) return

      const res = await fetch(
        `${API_URL}/users?email=${userFromStorage.email}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenFromStorage}`,
          },
        }
      )
      const data = await res.json()

      await AsyncStorage.setItem('user', JSON.stringify(data.data))
      setUser(JSON.parse(await AsyncStorage.getItem('user')))
      setToken(tokenFromStorage)
    }

    const updateWishListFromStorage = async () => {
      const wishListFromStorageJson = await AsyncStorage.getItem(
        'userWishlistedProducts'
      )
      if (!!!wishListFromStorageJson || wishListFromStorageJson === 'undefined')
        return

      const wishListFromStorage = JSON.parse(wishListFromStorageJson)

      if (!!!wishListFromStorage) return

      setUserWishlistedProducts([...wishListFromStorage])
    }

    updateUserData()
    updateWishListFromStorage()
  }, [])

  useEffect(() => {
    const fetchLikedArticles = async () => {
      const res = await fetch(
        `${API_URL}/users/liked-articles?email=${user.email}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const data = await res.json()

      if (!!!data.data) return

      const articleData = data.data

      for (let i = 0; i < articleData.length; i++) {
        if (!!!articleData[i]) continue

        const { imgSrc, h1Content, anchorHref } =
          articleData[i].rssId === 'custom-article'
            ? extractValuesFromDescriptionCustomArticles(articleData[i])
            : extractValuesFromDescription(articleData[i].description)

        articleData[i] = { ...articleData[i], imgSrc, h1Content, anchorHref }
      }

      setUserLikedArticles(data.data)
    }

    if (!!!user) return

    fetchLikedArticles()
  }, [user])

  useEffect(() => {
    const updateLocalStorage = async () => {
      await AsyncStorage.setItem(
        'userWishlistedProducts',
        JSON.stringify(userWishlistedProducts)
      )
    }

    updateLocalStorage()
  }, [JSON.stringify(userWishlistedProducts)])

  const logIn = async (
    { email, password }: LogInCredentials,
    errorCallback?: (error: string) => void,
    successCallback?: (error: string) => void
  ) => {
    try {
      const res = await fetch(`${API_URL}/auth/login`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })

      const data = await res.json()

      if (!!!data.data || res.status !== 200) {
        if (!!errorCallback) errorCallback(data.message)
        return
      }

      successCallback(data.message)

      await AsyncStorage.setItem('user', JSON.stringify(data.data.userData))
      await AsyncStorage.setItem('token', JSON.stringify(data.data.token))

      setUser(data.data.userData)
      setToken(data.data.token)
    } catch (error) {
      console.error(error)
    }
  }

  const logOut = async (navigation: any) => {
    await AsyncStorage.removeItem('user')
    await AsyncStorage.removeItem('token')

    setUser(null)
    setToken(null)

    navigation.navigate('Log In')
  }

  const signUp = async (
    { email, username, password, confirmPassword }: SignUpCredentials,
    errorCallback?: (error: string) => void,
    successCallback?: (error: string) => void
  ) => {
    try {
      if (password !== confirmPassword && !!errorCallback) {
        errorCallback('Passwords do not match!')
        return
      }

      const res = await fetch(`${API_URL}/auth/signup`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          username,
          password,
          confirmPassword,
        }),
      })

      const { message } = await res.json()

      if (res.status !== 200) {
        if (!!errorCallback) errorCallback(message)
        return
      }

      successCallback(message)
    } catch (error) {
      console.error(error)
    }
  }

  const addOrRemoveLikedArticle = async (
    { likedArticle, articleId }: LikeArticleParams,
    errorCallback?: (error: string) => void,
    successCallback?: (error: string) => void
  ) => {
    const res = await fetch(`${API_URL}/news/like`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        likedArticle,
        articleId,
        userId: user._id,
      }),
    })
    const { message } = await res.json()

    if (res.status !== 200) {
      if (!!errorCallback) errorCallback(message)
      return
    }

    if (likedArticle) {
      // add to arr
      setUser((prevUser) => {
        return {
          ...prevUser,
          likedArticles: [
            ...prevUser.likedArticles,
            {
              articleId,
              _id: 'null',
            },
          ],
        }
      })
    } else {
      // remove from arr
      setUser((prevUser) => {
        return {
          ...prevUser,
          likedArticles: prevUser.likedArticles.filter(
            (a) => a.articleId !== articleId
          ),
        }
      })
    }

    successCallback(message)
  }

  const addProductToWishlist = (product: UserWishListedProduct) => {
    setUserWishlistedProducts((list) => [...list, product])
    setIsEnabledWishlist(true)
  }

  const removeProductFromWishlist = (product: UserWishListedProduct) => {
    setUserWishlistedProducts((list) =>
      list.filter((item) => item.name !== product.name)
    )
  }

  const value = {
    UserState: {
      user,
      setUser,
    },
    logIn,
    logOut,
    signUp,
    addOrRemoveLikedArticle,
    userLikedArticles,
    setUserLikedArticles,
    userWishlistedProducts,
    setUserWishlistedProducts,
    addProductToWishlist,
    removeProductFromWishlist,
    isEnabledWishlist,
    setIsEnabledWishlist,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
