import { useEffect, useState } from 'react'
import { View, StyleSheet, Pressable, Platform } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome'
import { useArticles } from '../../context/ArticleContext'
import { useUser } from '../../context/UserContext'

type FooterParams = {
  navigation: any
  resetSearchInput?: () => void
  screen: string
}

export default function Footer({
  navigation,
  resetSearchInput = () => {
    return
  },
  screen = 'Home',
}: FooterParams) {
  const { UserState } = useUser()
  const { SearchState } = useArticles()
  const [navigatingToHome, setNavigatingToHome] = useState<boolean>(false)

  useEffect(() => {
    if (!navigatingToHome || !!SearchState.search) return

    resetSearchInput()
    navigation.navigate('Home')
    setNavigatingToHome(false)
  }, [navigatingToHome])

  return (
    <View
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: Platform.OS === 'web' ? 55 : 75,
        backgroundColor: 'black',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: '#144270',
          paddingVertical: 10,
          position: 'absolute',
          height: Platform.OS === 'web' ? 55 : 75,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 20,
        }}
      >
        <Pressable
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            marginBottom: Platform.OS === 'web' ? 0 : 25,
            height: 40,
            width: 60,
            backgroundColor:
              screen === 'Home' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
            borderRadius: 5,
          }}
          onPress={() => {
            SearchState.setSearch('')
            setNavigatingToHome(true)
          }}
        >
          <Icon name="home" size={24} color="white" />
        </Pressable>
        <Pressable
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            marginBottom: Platform.OS === 'web' ? 0 : 25,
            height: 40,
            width: 60,
            backgroundColor:
              screen === 'Search' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
            borderRadius: 5,
          }}
          onPress={() => {
            navigation.navigate('Search')
          }}
        >
          <Icon name="search" size={22} color="white" />
        </Pressable>
        <Pressable
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            marginBottom: Platform.OS === 'web' ? 0 : 25,
            height: 40,
            width: 60,
            backgroundColor:
              screen === 'Profile' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
            borderRadius: 5,
          }}
          onPress={() => {
            UserState.user
              ? navigation.navigate('Profile')
              : navigation.navigate('Sign Up')
          }}
        >
          <Icon name="user" size={22} color="white" />
        </Pressable>
      </View>
    </View>
  )
}
