import { View } from 'react-native'
import CustomArticle from '../components/Articles/CustomArticle'
import { IS_ON_WEB, SCREEN_HEIGHT } from '../utils/settings'
import tw from 'twrnc'
import ShareButton from '../components/Articles/ShareButton'
import Footer from '../components/Layout/Footer'

export default function CustomArticleScreen({ navigation, route }) {
  // const articleTitle = route.params.article.title
  // const articleDescription = route.params.article.description
  // navigation.setOptions({
  //   title: articleTitle,
  //   description: articleDescription,
  // })

  return (
    <View style={{ flex: 1 }}>
      <View
        style={[
          tw`flex bg-white`,
          {
            overflow: 'hidden',
            height: SCREEN_HEIGHT,
            paddingBottom: IS_ON_WEB ? 0 : 50,
          },
        ]}
      >
        <CustomArticle article={route.params.article} />
        <ShareButton
          articleLink={`https://scrollflip.com/?articleId=${route.params.article._id}`}
        />
      </View>

      {IS_ON_WEB ? <></> : <Footer navigation={navigation} screen="Home" />}
    </View>
  )
}
