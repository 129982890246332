import { ActivityIndicator, SafeAreaView, View } from 'react-native'
import { useArticles } from '../context/ArticleContext'
import { useEffect, useRef, useState } from 'react'
import { StatusBar } from 'expo-status-bar'
import tw from 'twrnc'
import { IS_ON_WEB } from '../utils/settings'
import ArticlesListWeb from '../components/Articles/ArticlesListWeb'
import ArticlesList from '../components/Articles/ArticlesList'
import Footer from '../components/Layout/Footer'

export default function ArticlesScreen({ navigation, route }) {
  const {
    ArticlesState,
    fetchArticles,
    refreshArticles,
    CategoryState,
    findArticle,
  } = useArticles()
  const [refreshing, setRefreshing] = useState<boolean>(false)
  const [currentScreen, setCurrentScreen] = useState(route.name)
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(true)
  const currentScreenRef = useRef(currentScreen)

  // Custom articles links for web
  useEffect(() => {
    if (!!!window.location) return

    if (window.location.toString().includes('?articleId=')) {
      const fetchArticle = async () => {
        const params = new URLSearchParams(window.location.search)
        const articleIdParam = params.get('articleId')
        const article = await findArticle(articleIdParam)

        if (!!article) {
          navigation.navigate('CustomArticle', { article })
        }
      }

      fetchArticle()
    }
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('state', () => {
      const currentRoute =
        navigation.getState().routes[navigation.getState().index]

      setCurrentScreen(currentRoute.name)

      if (currentScreenRef.current !== currentRoute.name)
        setIsLoadingScreen(true)
    })

    return unsubscribe
  }, [navigation])

  useEffect(() => {
    if (currentScreen === CategoryState.category) return

    CategoryState.setCategory(currentScreen === 'Home' ? '' : currentScreen)
  }, [currentScreen])

  const onRefresh = () => {
    setRefreshing(true)
    setTimeout(async () => {
      await refreshArticles()
      setRefreshing(false)
    }, 1000)
  }

  if (isLoadingScreen) {
    setTimeout(() => {
      setIsLoadingScreen(false)
    }, 250)

    return (
      <View
        style={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    )
  }

  return (
    <View
      style={[
        tw`bg-white`,
        {
          flex: 1,
          alignItems: 'center',
          overflow: 'hidden',
          justifyContent: 'center',
          height: 'full',
        },
      ]}
    >
      <StatusBar style="auto" />
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        {ArticlesState.isLoading ? (
          <View
            style={{
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <ActivityIndicator size="large" />
          </View>
        ) : IS_ON_WEB ? (
          <ArticlesListWeb
            articles={ArticlesState.articles}
            fetchArticles={fetchArticles}
          />
        ) : (
          <ArticlesList
            articles={ArticlesState.articles}
            refreshing={refreshing}
            onRefresh={onRefresh}
            fetchArticles={fetchArticles}
          />
        )}
      </SafeAreaView>
      {IS_ON_WEB ? <></> : <Footer navigation={navigation} screen="Home" />}
    </View>
  )
}
