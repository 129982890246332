import { useState } from 'react'
import { Text, StyleSheet, Pressable } from 'react-native'
import { IS_ON_DESKTOP } from '../utils/settings'

type BtnProps = {
  style?: any
  textStyle?: any
  text?: string
  onPress?: Function
  color?: string
  hoverColor?: string
}

export default function Btn({
  style,
  textStyle,
  text,
  onPress,
  color,
  hoverColor,
}: BtnProps) {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  if (!!!hoverColor) hoverColor = '#103152'
  if (!!!color) color = '#144270'

  return !IS_ON_DESKTOP ? (
    <Pressable
      onPress={() => onPress()}
      style={{ ...styles.button, ...style, backgroundColor: color }}
    >
      <Text style={{ ...styles.buttonText, ...textStyle }}>{text}</Text>
    </Pressable>
  ) : (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => onPress()}
      style={{
        backgroundColor: isHovered ? hoverColor : color,
        transition: 'all 125ms ease-in-out',
        borderRadius: 2,
        paddingRight: 25,
        paddingLeft: 25,
        paddingTop: 10,
        paddingBottom: 10,
        fontWeight: 'bold',
        cursor: 'pointer',
        ...style,
      }}
    >
      <Text style={{ ...styles.buttonText, ...textStyle }}>{text}</Text>
    </div>
  )
}

const styles = StyleSheet.create({
  button: {
    paddingRight: 25,
    paddingLeft: 25,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#144270',
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
})
