import { View, Pressable } from 'react-native'
import { useUser } from '../../context/UserContext'
import IconFa from 'react-native-vector-icons/FontAwesome'

export default function WishListButton() {
  const { setIsEnabledWishlist } = useUser()
  const toggleSwitch = () => setIsEnabledWishlist((prevState) => !prevState)

  return (
    <View style={{ position: 'relative' }}>
      <View>
        <Pressable onPress={toggleSwitch}>
          <IconFa name={'heart-o'} size={22} color={'black'} />
        </Pressable>
      </View>
    </View>
  )
}
