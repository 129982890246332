import { useEffect, useState } from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native'
import tw from 'twrnc'
import Btn from '../components/Btn'
import { useUser } from '../context/UserContext'
import { IS_ON_DESKTOP } from '../utils/settings'
import { showMessage } from 'react-native-flash-message'

export default function LoginScreen({ navigation }) {
  const { logIn } = useUser()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [logInFailed, setLogInFailed] = useState<boolean>(true)

  useEffect(() => {
    if (!logInFailed) {
      setTimeout(() => {
        navigation.navigate('Home')
      }, 750)

      setLogInFailed(true)
    }
  }, [logInFailed])

  const loginHandler = async () => {
    if (!!!email || !!!password) {
      showMessage({
        message: 'Please fill out all the required fields!',
        type: 'danger',
        hideOnPress: true,
      })
      return
    } else if (password.length < 8) {
      showMessage({
        message: 'Invalid E-Mail or Password!',
        type: 'danger',
        hideOnPress: true,
      })
      return
    }

    await logIn(
      { email, password },
      (error) =>
        showMessage({
          message: error,
          type: 'danger',
          hideOnPress: true,
        }),
      () => {
        showMessage({
          message: 'Successfully logged in!',
          type: 'success',
          hideOnPress: true,
        })
        setLogInFailed(false)
      }
    )
  }

  return (
    <View style={[tw`bg-white pb-30`, styles.container]}>
      <Text style={styles.heading}>Welcome Back!</Text>
      <View style={styles.inputContainer}>
        <Text style={styles.label}>Email </Text>
        <TextInput style={styles.input} value={email} onChangeText={setEmail} />
      </View>
      <View style={styles.inputContainer}>
        <Text style={styles.label}>Password</Text>
        <TextInput
          style={styles.input}
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
      </View>
      <Btn
        text="Log In"
        style={{
          width: IS_ON_DESKTOP ? '25%' : '80%',
          textAlign: 'center',
          borderRadius: 8,
        }}
        onPress={loginHandler}
      />
      <Text style={styles.linkContainer}>
        Already have an account?{' '}
        <Text
          style={styles.link}
          onPress={() => navigation.navigate('Sign Up')}
        >
          Sign up
        </Text>
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    marginBottom: 30,
    color: '#144270',
    fontSize: 30,
    fontWeight: '500',
  },
  inputContainer: {
    marginBottom: 20,
    width: IS_ON_DESKTOP ? '30%' : '80%',
  },
  label: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 'normal',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingVertical: 8,
    fontSize: 16,
    opacity: 0.75,
  },
  button: {
    backgroundColor: '#144270',
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  buttonText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 'bold',
  },
  link: {
    color: '#144270',
    fontWeight: '600',
  },
  linkContainer: {
    marginBottom: 15,
    marginTop: 15,
  },
})
