import { useEffect, useState } from 'react'
import { View, Text, TextInput, StyleSheet } from 'react-native'
import tw from 'twrnc'
import { IS_ON_DESKTOP } from '../utils/settings'
import Btn from '../components/Btn'
import { useUser } from '../context/UserContext'
import { showMessage } from 'react-native-flash-message'

export default function SignupScreen({ navigation }) {
  const { signUp } = useUser()
  const [username, setUsername] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [signUpFailed, setSignUpFailed] = useState<boolean>(true)

  useEffect(() => {
    if (!signUpFailed) {
      setTimeout(() => {
        navigation.navigate('Log In')
      }, 1000)

      setSignUpFailed(true)
    }
  }, [signUpFailed])

  const signupHandler = async () => {
    if (!!!username || !!!password || !!!confirmPassword || !!!email) {
      showMessage({
        message: 'Please fill out all the required fields!',
        type: 'danger',
      })
      return
    } else if (username.length < 3) {
      showMessage({
        message: 'Username should be 3 characters or longer!',
        type: 'danger',
      })
      return
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      showMessage({
        message: 'E-Mail is not valid!',
        type: 'danger',
      })
      return
    } else if (password !== confirmPassword) {
      showMessage({
        message: 'Passwords do not match!',
        type: 'danger',
      })
      return
    } else if (password.length < 8) {
      showMessage({
        message: 'Password should be 8 characters or longer!',
        type: 'danger',
      })
      return
    }

    await signUp(
      { email, password, confirmPassword, username },
      (error) =>
        showMessage({
          message: error,
          type: 'danger',
        }),
      () => {
        showMessage({
          message: 'Account created successfully!',
          type: 'success',
        })
        setSignUpFailed(false)
      }
    )
  }

  return (
    <View style={[tw`pb-10 bg-white`, styles.container]}>
      <Text style={styles.heading}>Create Account</Text>
      <Text style={[styles.label, { marginBottom: 40 }]}>
        Just a few quick things to get started!
      </Text>
      <View style={styles.inputContainer}>
        <Text style={styles.label}>Username</Text>
        <TextInput
          style={styles.input}
          value={username}
          onChangeText={setUsername}
        />
      </View>
      <View style={styles.inputContainer}>
        <Text style={styles.label}>Email</Text>
        <TextInput
          style={styles.input}
          value={email}
          onChangeText={setEmail}
          keyboardType="email-address"
        />
      </View>
      <View style={styles.inputContainer}>
        <Text style={styles.label}>Password </Text>
        <TextInput
          style={styles.input}
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
      </View>
      <View style={styles.inputContainer}>
        <Text style={styles.label}>Confirm Password</Text>
        <TextInput
          style={styles.input}
          value={confirmPassword}
          onChangeText={setConfirmPassword}
          secureTextEntry
        />
      </View>

      <Btn
        text="Sign Up"
        style={{
          width: IS_ON_DESKTOP ? '25%' : '80%',
          textAlign: 'center',
          borderRadius: 8,
        }}
        onPress={signupHandler}
      />
      <View style={styles.inputContainer}>
        <Text style={styles.linkContainer}>
          Already have an account?{' '}
          <Text
            style={styles.link}
            onPress={() => navigation.navigate('Log In')}
          >
            Login
          </Text>
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    marginBottom: 20,
    width: IS_ON_DESKTOP ? '30%' : '80%',
  },
  heading: {
    marginBottom: 10,
    color: '#144270',
    fontSize: 30,
    fontWeight: '500',
  },
  label: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 'normal',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
    paddingHorizontal: 10,
    paddingVertical: 8,
    fontSize: 16,
    opacity: 0.75,
  },
  button: {
    backgroundColor: '#144270',
    borderRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 10,
    width: 'auto',
  },
  buttonText: {
    color: 'red',
    fontWeight: '900',

    fontSize: 18,
  },
  buttonHover: {
    backgroundColor: 'black',
  },
  link: {
    color: '#144270',
    fontWeight: '600',
  },
  linkContainer: {
    marginBottom: 15,
    marginTop: 15,
    textAlign: 'center',
  },
})
