import { View, Text } from 'react-native'
import tw from 'twrnc'

export default function NoResults() {
  return (
    <View style={[tw`px-8 py-2 mx-5 mt-20 text-white rounded-md `]}>
      <Text style={tw`flex items-center justify-center text-3xl text-gray-500`}>
        Oops, No Results!
      </Text>
    </View>
  )
}
