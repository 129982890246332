import { type ReactNode } from 'react'
import { Pressable, View, Text, Modal, StyleSheet, Image } from 'react-native'
import { IS_ON_DESKTOP } from '../../utils/settings'
import { useFonts } from 'expo-font'
import Close from 'react-native-vector-icons/Fontisto'

type UniversalModalProps = {
  close: () => void
  visible: boolean
  title?: string
  text?: string
  content?: ReactNode
}

export default function UniversalModal({
  close,
  visible,
  title,
  text,
  content,
}: UniversalModalProps) {
  const [fontsLoaded] = useFonts({
    'Lato-Light': require('../../../assets/fonts/Lato-Light.ttf'),
    'Lato-Regular': require('../../../assets/fonts/Lato-Regular.ttf'),
    'Lato-Bold': require('../../../assets/fonts/Lato-Bold.ttf'),
  })

  if (!fontsLoaded) {
    return <></>
  }

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      onRequestClose={close}
    >
      <View style={styles.outerModal}>
        <View style={styles.modalContainer}>
          <View style={styles.modalHeader}>
            <View style={styles.headerFirstView}>
              <Image
                source={require('../../../assets/favicon.png')}
                style={{ objectFit: 'scale-down', height: 25, width: 25 }}
              />
            </View>
            <Close onPress={close} name={'close-a'} size={18} color={'white'} />
          </View>
          <View style={styles.mainModalSection}>
            <View style={styles.modalFirstRow}>{title}</View>
            <View style={styles.modalSecondRow}>
              {!!text && (
                <Text style={{ fontFamily: 'Lato-Regular' }}>{text}</Text>
              )}
              {!!content && content}
            </View>
          </View>
          <View style={styles.pressableSection}>
            <Pressable onPress={close} style={styles.pressableStyle}>
              <Text style={{ fontFamily: 'Lato-Regular', color: 'white' }}>
                Close
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  outerModal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContainer: {
    backgroundColor: 'white',
    borderRadius: 2,
    width: IS_ON_DESKTOP ? '30%' : '80%',
    height: IS_ON_DESKTOP ? '25%' : '42%',
  },
  modalHeader: {
    height: 40,
    borderRadius: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.88)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 5,
  },
  headerFirstView: {
    flex: 1,
    flexDirection: 'row',
  },
  textStyle: {
    color: 'white',
    paddingHorizontal: 5,
    paddingVertical: 5,
    fontSize: 18,
    fontFamily: 'Lato-Regular',
  },
  mainModalSection: {
    paddingHorizontal: 20,
    paddingTop: 40,
    flexDirection: 'column',
    borderBottom: 1,
  },
  modalFirstRow: {
    flex: 1,
    justifyContent: 'flex-start',
    fontSize: 30,
    fontFamily: 'Lato-Regular',
    color: '#e74f4e',
    fontWeight: '700',
  },
  modalSecondRow: {
    padding: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  pressableSection: {
    borderTopWidth: 1,
    borderColor: '#CCCCCC',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 80,
    marginTop: 20,
    padding: 20,
  },
  pressableStyle: {
    color: 'white',
    backgroundColor: '#e74f4e',
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 2,
  },
})
