import { useState } from 'react'
import { View, Text, TextInput, Pressable } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import tw from 'twrnc'
import PrivacyModal from './Modals/PrivacyModal'
import { IS_ON_DESKTOP } from '../utils/settings'
import Btn from './Btn'

export default function NewsletterSubscription() {
  const [email, setEmail] = useState('')
  const [isPrivacyVisible, setPrivacyVisible] = useState(false)

  const handleSubscribe = () => {
    if (email === '') {
      showMessage({
        message: 'You must enter your email to subscribe!',
        type: 'danger',
      })
    } else {
      showMessage({
        message: 'You are now subscribed to our newsletter!',
        type: 'success',
      })
    }
  }

  return (
    <>
      <PrivacyModal
        visible={isPrivacyVisible}
        onClose={() => setPrivacyVisible(false)}
      />
      <View style={[tw`items-center justify-center flex-1`]}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 24,
              fontFamily: 'Lato-Bold',
            }}
          >
            Subscribe to our Newsletter!
          </Text>
          <Text
            style={[tw`mt-1 text-sm text-gray-500`, { textAlign: 'center' }]}
          >
            Get an email for every new happenings in the world
          </Text>
        </View>
        <View style={[tw`px-8 py-2 mx-5 mt-10 rounded-md`]}>
          <TextInput
            style={[
              tw`px-4 py-2 mb-2 text-gray-500 bg-white rounded-md`,
              { fontSize: 16 },
              { backgroundColor: '#F0F0F0' },
            ]}
            placeholder="Enter your email"
            placeholderTextColor="#333333"
            keyboardType="email-address"
            autoCapitalize="none"
            autoCorrect={false}
            value={email}
            onChangeText={(text) => setEmail(text)}
          />
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Btn
              style={{
                marginTop: IS_ON_DESKTOP ? 5 : 5,
                marginBottom: IS_ON_DESKTOP ? 10 : 5,
                minWidth: IS_ON_DESKTOP ? 200 : 300,
                textAlign: 'center',
              }}
              onPress={handleSubscribe}
              text="Subscribe"
            />
          </View>
          <Text>
            <Text
              style={[tw`mt-2 text-sm text-gray-500`, { textAlign: 'center' }]}
            >
              You can opt out at any time. By signing up, you accept the{' '}
            </Text>
            <Pressable>
              <Text style={{ textDecorationLine: 'underline' }}>
                Terms of Use
              </Text>
            </Pressable>
            <Text> and </Text>
            <Pressable onPress={() => setPrivacyVisible(true)}>
              <Text style={{ textDecorationLine: 'underline' }}>
                Privacy Policy.
              </Text>
            </Pressable>
          </Text>
        </View>
      </View>
    </>
  )
}
