import { View, Text, StyleSheet, Dimensions, Pressable } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import { useFonts } from 'expo-font'
import { IS_ON_DESKTOP, IS_ON_WEB } from '../utils/settings'
import { useUser } from '../context/UserContext'
import Footer from '../components/Layout/Footer'

export default function ProfileScreen({ navigation }) {
  const { UserState, logOut } = useUser()

  const [fontsLoaded] = useFonts({
    'Lato-Regular': require('../../assets/fonts/Lato-Regular.ttf'),
  })

  if (!fontsLoaded) {
    return null
  }

  return (
    <View style={[styles.container, styles.bgWhite]}>
      <StatusBar style="auto" />
      {UserState.user !== null && (
        <View style={styles.content}>
          <View style={styles.info}>
            <Text
              style={[
                styles.label,
                styles.textLg,
                styles.fontBold,
                { color: '#144270' },
              ]}
            >
              Username
            </Text>
            <Text style={[styles.text, styles.lato, { color: '#8D9093' }]}>
              {UserState.user.username}
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.info}>
            <Text
              style={[
                styles.label,
                styles.textLg,
                styles.fontBold,
                { color: '#144270' },
              ]}
            >
              Email
            </Text>
            <Text style={[styles.text, styles.lato, { color: '#8D9093' }]}>
              {UserState.user.email}
            </Text>
          </View>
          <View style={styles.divider} />
          <View style={styles.info}>
            <Pressable>
              <Text
                style={[
                  styles.label,
                  styles.textLg,
                  styles.fontBold,
                  { color: '#144270' },
                ]}
              >
                Likes
              </Text>
            </Pressable>
            <Pressable
              onPress={() => {
                navigation.navigate('Likes')
              }}
              style={styles.textContainer}
            >
              <Text
                style={[
                  styles.text,
                  styles.lato,
                  styles.arrow,
                  { color: '#8D9093' },
                ]}
              >
                ➔
              </Text>
            </Pressable>
          </View>
          <View style={styles.divider} />
          <View style={styles.info}>
            <Text
              style={[
                styles.label,
                styles.textLg,
                styles.fontBold,
                { color: '#144270' },
              ]}
            >
              Settings
            </Text>
            <View style={styles.textContainer}>
              <Text
                style={[
                  styles.text,
                  styles.lato,
                  styles.arrow,
                  { color: '#8D9093' },
                ]}
              >
                ➔
              </Text>
            </View>
          </View>
          <View style={styles.divider} />
          <View style={styles.info}>
            <Pressable>
              <Text
                style={[
                  styles.label,
                  styles.textLg,
                  styles.fontBold,
                  { color: '#144270' },
                ]}
              >
                Log Out
              </Text>
            </Pressable>
            <Pressable
              onPress={() => {
                logOut(navigation)
              }}
              style={styles.textContainer}
            >
              <Text
                style={[
                  styles.text,
                  styles.lato,
                  styles.arrow,
                  { color: '#8D9093' },
                ]}
              >
                ➔
              </Text>
            </Pressable>
          </View>
          <View style={styles.divider} />
        </View>
      )}
      {IS_ON_WEB ? <></> : <Footer navigation={navigation} screen="Profile" />}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: IS_ON_DESKTOP ? 50 : 20,
    paddingHorizontal: IS_ON_DESKTOP ? Dimensions.get('window').width / 5 : 20,
  },
  bgWhite: {
    backgroundColor: 'white',
  },
  content: {
    flex: 1,
  },
  info: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    marginRight: 10,
  },
  textLg: {
    fontSize: IS_ON_DESKTOP ? 22 : 16,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontSize: IS_ON_DESKTOP ? 18 : 14,
    color: '#144270',
  },
  lato: {
    fontFamily: 'Lato-Regular',
  },
  arrow: {
    fontSize: 20,
    marginLeft: 5,
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
    marginBottom: 20,
  },
})
