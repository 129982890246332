import { useEffect } from 'react'
import { Text, ScrollView, Dimensions } from 'react-native'
import RenderHtml from 'react-native-render-html'
import tw from 'twrnc'
import { useUser } from '../../context/UserContext'
import { IS_ON_DESKTOP } from '../../utils/settings'
import AddOrRemoveToWhishlistButton from './AddOrRemoveToWhishlistButton'
import { createRoot } from 'react-dom/client'
import { getTimeSinceArticleCreated } from '../../utils/article'
import { type Article } from '../../types/articles'

type CustomArticleProps = {
  article: Article
}

export default function CustomArticle({ article }: CustomArticleProps) {
  const {
    userWishlistedProducts,
    addProductToWishlist,
    removeProductFromWishlist,
  } = useUser()

  const articleContent = article.content
  const source = {
    html: articleContent.substring(0, articleContent.length - 120),
  }
  const articleDate = new Date(article.created)

  useEffect(() => {
    if (!!!document) return

    const xpath = "//div[contains(text(),'NUM_OF_PRODUCTS:')]"
    const numOfProductsElem: any = document.evaluate(
      xpath,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue

    if (!!!numOfProductsElem) return

    const numOfProducts = numOfProductsElem.innerText.slice(16)

    for (let i = 0; i < numOfProducts; i++) {
      const xpath = `//div[contains(text(),'PRODUCT${i}:')]`
      const productElem: any = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue

      if (!!!productElem) continue

      const root = createRoot(productElem)
      root.render(
        <AddOrRemoveToWhishlistButton
          product={JSON.parse(productElem.innerText.slice(9))}
          userWishlistedProducts={userWishlistedProducts}
          addProductToWishlist={addProductToWishlist}
          removeProductFromWishlist={removeProductFromWishlist}
        />
      )
    }
  }, [document, JSON.stringify(userWishlistedProducts)])

  return (
    <ScrollView
      style={{
        paddingLeft: IS_ON_DESKTOP ? Dimensions.get('window').width / 5 : 15,
        paddingRight: IS_ON_DESKTOP ? Dimensions.get('window').width / 5 : 15,
        paddingTop: 25,
        paddingBottom: 25,
      }}
    >
      <Text style={tw`mt-3 font-semibold underline`}>{article.category}</Text>
      <Text>Posted {getTimeSinceArticleCreated(articleDate)}</Text>

      <Text style={tw`mt-3 mb-2 text-3xl font-semibold`}>{article.title}</Text>
      <Text style={tw`mb-2 text-base`}>{article.description}</Text>
      <Text style={tw`mb-4`}>
        by{' '}
        <Text style={[tw`font-bold text-red-700`, { color: '#e01e26' }]}>
          {article.author}
        </Text>
      </Text>

      <RenderHtml
        source={source}
        contentWidth={Dimensions.get('window').width}
        enableExperimentalMarginCollapsing={true}
      />
    </ScrollView>
  )
}
